import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaRegEnvelope } from "react-icons/fa6";
import { TbBrandLinkedin } from "react-icons/tb";
import { SiDjango } from "react-icons/si";
import { FiExternalLink } from "react-icons/fi";
import Fluid from "./Fluid"
import { FaReact, FaBootstrap, FaPython, FaGithub, FaLinkedin, FaPhone } from 'react-icons/fa';
import MazeGenerator from './MazeGenerator';

function ProjectLeft({children,src,title,link}){
  return(
    <>
      <Row className='py-3  d-flex justify-content-between
      '>
        <div className='col-md project my-4'>
          <img className='project-img' src={src} alt="" />
        </div>
        
        <div className='col-md my-auto'>
          <h4 className='title-color' >{title}</h4>
          <h5 className="text-color text-justify" dangerouslySetInnerHTML={{  __html: children }} />

          <a className='link fs-3' href={link}>
            <FiExternalLink />
          </a>
        </div>
      </Row>
    </>
  )
}

function ProjectRight({children,src,title,link}){
  return(
    <>
      <Row className='py-3 d-flex gap-5'>
        <div className='col-md my-auto'>
          <h4 className='title-color' >{title}</h4>
          <h5 className="text-color text-justify" dangerouslySetInnerHTML={{  __html: children }} />

          <a className='link fs-3' href={link}>
            <FiExternalLink />
          </a>
        </div>

        <div className='col-md project'>
          <img className='project-img' src={src} alt="" />
        </div>
      </Row>
    </>
  )
}



const Home = () => {

  return (
    <div className="text-light static-width mx-auto" style={{overflowX:"hidden"}}>
      <div className="">

        <div id='profile' className="mx-auto" src="profile2.png" alt="" />
        <div className='p-5'>
          <p className='text-center' style={{fontSize:"3rem"}}>Software Developer <img style={{width:"50px",height:"50px"}} src="img/dev.png" alt="" /></p>
          <p className="fs-5 text-color text-center">Hey, I'm Benjamin Sitbon! I code up a storm and turn ideas into awesome software. 🚀</p>

          <div className='d-flex flex-row justify-content-center'>
            <a className='link'  href='https://www.linkedin.com/in/benjamin-sitbon-66a389226/'>
              <TbBrandLinkedin className='zoom fs-3'/>
            </a>

            <a className='mx-2 link' href='mailto:benjaminsitbon@hotmail.com'>
              <FaRegEnvelope className="zoom fs-3" /> 
            </a>
          </div>
        </div>
      </div>

      <div className='wfc d-flex flex-row p-5 mb-5 mx-auto gap-5'>
        <h2>Skills</h2>
        <Row className='gap-5 row-cols-4'>
          <FaBootstrap className="zoom fs-1 col" style={{color:"#c6addc"}}/>
          <FaReact className="zoom fs-1 col" style={{color:"#70f3ff"}}/>
          <SiDjango className="zoom fs-1 col" style={{color:"#90ff90"}}/>
          <FaPython className="zoom fs-1 col" style={{color:"#ffff90"}}/>
        </Row>
      </div>




      <Row id="About-me" className="p-5 static-width mx-auto">
        <div className='col-md p-2 pb-5 pt-0'>
          <img className='rounded-5 static-img'  src='img/computer-science.jpg' />
        </div>
        
        
        <div className='col-md p-2 ' >
          <h4 className='title-color'>About me</h4>
          <h3 className='mb-4 text-justify' >A budding software engineer passionate about crafting digital solutions. </h3>
          <h6 className="text-color text-justify" style={{lineHeight:"2rem"}}>My expertise spans Python, Django, React, HTML, CSS, and JavaScript. Navigating the early stages of my career, I find joy in turning ideas into code and creating seamless user experiences. 
            Equally adept at frontend and backend development, I bring a versatile skill set to every project. 
            Let's connect and explore the possibilities of tech together !</h6>
        </div>
      </Row>

      
      <Row id="Projects" className="static-width mx-auto p-5">

        <div className='col-md p-2'>

          <h3 className='title-color'>Portfolio</h3>
          <h3 className=''>A blend of creativity, precision, and passion in every project.</h3>


          <div className='col-md' >
            <ProjectLeft title="Showcase website" src="./img/showcase-brand.png" link="https://showcase-brand.bss.design/">Unlock the door to your dream studio with our hassle-free platform.
            From intimate artistic nooks to vibrant workspaces, your perfect studio awaits just a click away! 
            Join us in a seamless journey of studio discovery. 🎨✨</ProjectLeft>

            <ProjectRight title="Admin dashboard" src="./img/admin-dashboard.png" link="https://admin-dashboard.bss.design/">
            Navigate the world of efficiency with our intuitive admin dashboard platform. Seamlessly explore, compare, and optimize your data effortlessly. 
            From streamlined analytics to powerful management tools, your ideal admin interface is just a click away! 
            Welcome to a new era of effortless control and organization. 📊🚀
            </ProjectRight>

            <Row className='my-5 d-flex'>
              <div className='col-md'>
                <Fluid heigth={100} width={100}></Fluid>
              </div>
              <div className='col-md mt-5'>
                <h4 className='title-color' >Heat transmission Simulation</h4>
                <h4 className="text-color">represented by colors from <b style={{color:"rgb(255,84,84)"}}>red 🔥</b> to <b style={{color:"rgb(84,84,255)"}}>blue 🧊</b> </h4>
                <h5 className="text-color text-justify"> The simulation employs the Laplace equation, a partial differential equation describing the distribution of temperature in a conductive medium over time. The Laplace formula represents a steady-state condition where temperature remains constant with respect to time.</h5>
              </div>
            
            </Row>


            <Row className='my-5 d-flex'>
              <div className='col-md'>
                <MazeGenerator height={50} width={50}></MazeGenerator>
              </div>
              <div className='col-md mt-5'>
                <h4 className='title-color' >Maze Generator</h4>
                <h5 className="text-color text-justify"> This maze generator has been implemented using the Depth-First Search algorithm, a method that explores paths deeply before backtracking. It creates mazes by traversing through cells, carving passages and forming interconnected paths. The algorithm's depth-first approach results in mazes with long corridors and dead-ends, providing a different maze-solving experience compared to other algorithms.</h5>
              </div>
            
            </Row>
          </div>
        </div>
      </Row>

      <Row id="Contact" className='p-5 static-width position-relative start-50 translate-middle-x'>
        <h2 className='title-color'>Contact</h2>
        <h4>Let's get in touch !</h4>

        <Row className='d-flex flex-row'>

          <div className='col-md py-5 d-flex flex-column'>
            
            <div className='d-flex flex-row'>
              <FaRegEnvelope className='fs-3 my-3 me-3 title-color'/> 
              <h4 className='hfc my-3 mx-3'>Mail</h4>
            </div>

            <a className='text-color decoration-none fs-5' href='mailto:benjaminsitbon@hotmail.com'>benjaminsitbon@hotmail.com</a>
            
          </div>

          <div className='col-md py-5 d-flex flex-column'>
            
            <div className='d-flex flex-row'>
              <FaPhone className='fs-3 my-3 me-3 title-color'/> 
              <h4 className='hfc my-3 mx-3'>Mobile</h4>
            </div>

            <a className='text-color decoration-none fs-5' href='tel:+41789555845'>+41 78 955-5845</a>
            
          </div>
        </Row>
      </Row>
    </div>
  );
};

export default Home;
