import { height } from '@mui/system';
import React, { useState, useEffect, useCallback, useRef} from 'react';

const MazeGenerator = ({ height, width }) => {
  const [maze, setMaze] = useState(Array.from({ length: height }, () =>
  Array.from({ length: width }, () => 0)));
  const [start, setStart] = useState(0);
  const [x,setX] = useState(0)
  const [y,setY] = useState(0)
  
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function nextPath(){
    for(let i=1; i<height-1; i++){
      for(let j =1; j<width-1; j++){
        if(maze[i][j] == 1 && nextDirection(j,i)[0] != 0){
          return [i,j]
        }
      }
    }
    return false
  }

  function inBounds(xPos,yPos){
    return ((xPos > 0 && xPos < width-1) && (yPos > 0 && yPos < height-1))
  }

  function colisions(xPos,yPos){
    let square1 = maze[yPos-1][xPos-1] + maze[yPos-1][xPos] + maze[yPos][xPos-1] + 1 //+ !inBounds(xPos-1,yPos-1) + !inBounds(xPos,yPos-1) + !inBounds(xPos-1,yPos)
    let square2 = maze[yPos-1][xPos] + maze[yPos-1][xPos+1] + maze[yPos][xPos+1] + 1 //+ !inBounds(xPos,yPos-1) + !inBounds(xPos+1,yPos-1) + !inBounds(xPos+1,yPos)
    let square3 = maze[yPos][xPos-1] + maze[yPos+1][xPos] + maze[yPos+1][xPos-1] + 1 //+ !inBounds(xPos-1,yPos) + !inBounds(xPos,yPos+1) + !inBounds(xPos-1,yPos+1)
    let square4 = maze[yPos][xPos+1] + maze[yPos+1][xPos+1] + maze[yPos+1][xPos] + 1 //+ !inBounds(xPos+1,yPos) + !inBounds(xPos+1,yPos+1) + !inBounds(xPos,yPos+1)

    if(square1 > 3 || square2 > 3 || square3 > 3 || square4 > 3 || square1+square2+square3+square4 > 7){
      return true
    }
    return false
  }

  const nextDirection = useCallback((xPos,yPos) => {
    let directions = [[-1,0],[0,-1],[1,0],[0,1]]
    let possibleDirections = []
    
    for(let direction of directions){
      let newX = xPos+direction[0];
      let newY = yPos+direction[1];

      if(inBounds(newX,newY)){
        if(!colisions(newX,newY) && !maze[newY][newX]){
          possibleDirections.push([newY,newX])
        }
      }
    }

    if(possibleDirections.length > 0){
      return possibleDirections[getRandomInt(0,possibleDirections.length-1)]
    }else{
      return [0,0]
    }
    
  },[colisions,inBounds,maze])
  
  const initMaze = useCallback(() => {

    const newMaze = Array.from({ length: height }, () =>
          Array.from({ length: width }, () => 0)
    );

    var randomX = getRandomInt(1,width-2)
    var randomY = getRandomInt(1,height-2)

    
    newMaze[randomY][randomX] = 1

    console.log("start: " + randomX + "," + randomY)

    setX(randomX)
    setY(randomY)
    setMaze(newMaze)
    
  },[height, width])



  const updateMaze = useCallback(() => {
    const newMaze = [...maze] //copie une liste dékà existante dans une nouvelle variable

    let next = nextDirection(x,y)
    
    if(next[0] == 0){
      let path = nextPath()
      if(!path){
        console.log("ici")
        for(let i = 1; i<height-1; i++){
          if(maze[i][1]){
            newMaze[i][0] = 1
            break
          }
        }

        for(let j=height-1; j>0; j--){
          if(maze[j][width-2]){
            newMaze[j][width-1] = 1
            break
          }
        }
        setMaze(newMaze)
        setStart(!start)
        return false
      }else{
        setX(path[1])
        setY(path[0])
        setMaze(newMaze)
        return true
      }
    }
    
    newMaze[next[0]][next[1]] = 1

    setX(next[1])
    setY(next[0])
    setMaze(newMaze)
  },[maze, nextDirection, start, nextPath,x,y])

  const handleClick = () =>{
    setStart(!start)
  }



  useEffect(() => {
    if(start){
      
      if(x!=0){
        updateMaze()
      }else{
        initMaze()
      }
      /*initMaze()
      const timer = setInterval(() => {
        updateMaze()
      }, 1);
      return () => clearInterval(timer);*/
    }else{
      setX(0)
    }
  }, [start,initMaze,updateMaze,x]);

  return (
    <div>
      <div className='d-flex column m-auto' style={{width:"fit-content"}}>
          {maze.map((row, rowIndex) => (
              <div key={rowIndex}>
                  {row.map((cell, colIndex) => (
                      <div className='pixel-2' style={{backgroundColor:`${cell === 0 ? "white":"black"}`}} key={colIndex}></div>
                  ))}
              </div>
          ))}
      </div>
            
      <button className='position-relative start-50 translate-middle-x mt-5 border border-light rounded p-3' style={
          {
            backgroundColor:"transparent", 
            color:"white", 
            fontSize:"20px"
          }
      } onClick={handleClick}>{start ? "Stop":"Generate"} </button>
    </div>
  );
};

export default MazeGenerator;
